@tailwind base;
@tailwind components;
@tailwind utilities;

apple-pay-button {
  -webkit-appearance: -apple-pay-button;
  appearance: -apple-pay-button;
  --apple-pay-button-width: 100%;
  --apple-pay-button-height: 42px;
  --apple-pay-button-border-radius: 9999px;
  --apple-pay-button-padding: 7px 15px;
  --apple-pay-button-box-sizing: border-box;
  border-radius: 9999px;
}
